<script>
export default {
  name: 'WistiaVideo',

  props: {
    devise: { type: Object },
    videoId: {
      type: String,
      default: '',
    },
    controlsHeight: {
      type: String,
      default: 'full',
    },
    controlsLocation: {
      type: String,
      default: 'bottom-right',
    },
    relativeOrAbsolute: {
      type: String,
      default: 'relative',
    },
    playOnLoad: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      divId: '',
      id: this.videoId,
      videoLoaded: false,
      currentVolume: 0.5,
      muted: true,
      isPlaying: false,
      inFullscreen: false,
    }
  },

  mounted() {
    this.setDivId()
    if (window) {
      window._wq = window._wq || []
      _wq.push({
        id: this.id,
        onReady: (video) => {
          this.videoLoaded = true
          video.bind('enterfullscreen', () => {
            this.inFullscreen = true
          })
          video.bind('cancelfullscreen', () => {
            this.inFullscreen = false
          })
          video.bind('play', () => {
            this.isPlaying = true
          })
          video.bind('pause', () => {
            this.isPlaying = false
          })
          video.releaseControls('devise')

          if (this.playOnLoad)
            this.togglePlay()
        },
      })
    }
  },

  methods: {
    togglePlay() {
      if (window.Wistia.api(this.divId).state() === 'playing') {
        window.Wistia.api(this.divId).pause()
        this.isPlaying = false
      }
      else {
        window.Wistia.api(this.divId).play()
        this.isPlaying = true
      }
    },

    toggleMute() {
      if (!this.muted) {
        window.Wistia.api(this.divId).mute()
        this.currentVolume = 0
        window.Wistia.api(this.divId).volume(0)
        this.muted = true
      }
      else {
        window.Wistia.api(this.divId).unmute()
        this.currentVolume = 0.5
        window.Wistia.api(this.divId).volume(0.5)
        this.muted = false
      }
    },

    toggleFullscreen() {
      window.Wistia.api(this.divId).requestFullscreen()
    },

    volume() {
      if (window.Wistia.api(this.divId))
        window.Wistia.api(this.divId).volume(this.currentVolume)
    },

    setVolume(e) {
      window.Wistia.api(this.divId).unmute()
      this.muted = false

      this.currentVolume = e.target.value
      this.volume()
    },
    setDivId() {
      let result = ''
      const characters
        = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < 5; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength),
        )
      }
      this.divId = result
    },
  },
}
</script>

<template>
  <div :class="[relativeOrAbsolute]">
    <ClientOnly>
      <div
        :id="divId" class="wistia_embed absolute z-0 inset-0" :class="[`wistia_async_${videoId}`]" width="100%"
        height="100%"
      />
    </ClientOnly>
    <div
      v-if="videoLoaded"
      class="absolute inset-0 z-30 pointer-events-none p-4 md:py-12 px-4 text-white flex"
      :class="{
        'justify-start':
          controlsLocation === 'top-left'
          || controlsLocation === 'bottom-left'
          || controlsLocation === 'middle-left',
        'justify-end':
          controlsLocation === 'top-right'
          || controlsLocation === 'bottom-right'
          || controlsLocation === 'middle-right',
        'justify-center':
          controlsLocation === 'top-center'
          || controlsLocation === 'bottom-center'
          || controlsLocation === 'middle-center',
        'items-start':
          controlsLocation === 'top-left'
          || controlsLocation === 'top-right'
          || controlsLocation === 'top-center',
        'items-end':
          controlsLocation === 'bottom-left'
          || controlsLocation === 'bottom-right'
          || controlsLocation === 'bottom-center',
        'items-center':
          controlsLocation === 'middle-left'
          || controlsLocation === 'middle-right'
          || controlsLocation === 'middle-center',
        'max-h-[62vh]': controlsHeight === 'half',
      }"
    >
      <div class="inline-flex items-center space-x-4">
        <button class="pointer-events-auto" @click="togglePlay">
          <svg
            v-if="!isPlaying" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
            class="w-6 h-6"
          >
            <path
              fill-rule="evenodd"
              d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
              clip-rule="evenodd"
            />
          </svg>
          <svg
            v-if="isPlaying" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
            class="w-6 h-6"
          >
            <path
              d="M15 6.75a.75.75 0 00-.75.75V18a.75.75 0 00.75.75h.75a.75.75 0 00.75-.75V7.5a.75.75 0 00-.75-.75H15zM20.25 6.75a.75.75 0 00-.75.75V18c0 .414.336.75.75.75H21a.75.75 0 00.75-.75V7.5a.75.75 0 00-.75-.75h-.75zM5.055 7.06C3.805 6.347 2.25 7.25 2.25 8.69v8.122c0 1.44 1.555 2.343 2.805 1.628l7.108-4.061c1.26-.72 1.26-2.536 0-3.256L5.055 7.061z"
            />
          </svg>
        </button>
        <div class="inline -mb-2 group relative pointer-events-auto">
          <button class="mb-0" @click="toggleMute">
            <svg v-if="muted" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
              <path
                d="M13.5 4.06c0-1.336-1.616-2.005-2.56-1.06l-4.5 4.5H4.508c-1.141 0-2.318.664-2.66 1.905A9.76 9.76 0 001.5 12c0 .898.121 1.768.35 2.595.341 1.24 1.518 1.905 2.659 1.905h1.93l4.5 4.5c.945.945 2.561.276 2.561-1.06V4.06zM17.78 9.22a.75.75 0 10-1.06 1.06L18.44 12l-1.72 1.72a.75.75 0 001.06 1.06l1.72-1.72 1.72 1.72a.75.75 0 101.06-1.06L20.56 12l1.72-1.72a.75.75 0 00-1.06-1.06l-1.72 1.72-1.72-1.72z"
              />
            </svg>
            <svg v-if="!muted" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
              <path
                d="M13.5 4.06c0-1.336-1.616-2.005-2.56-1.06l-4.5 4.5H4.508c-1.141 0-2.318.664-2.66 1.905A9.76 9.76 0 001.5 12c0 .898.121 1.768.35 2.595.341 1.24 1.518 1.905 2.659 1.905h1.93l4.5 4.5c.945.945 2.561.276 2.561-1.06V4.06zM18.584 5.106a.75.75 0 011.06 0c3.808 3.807 3.808 9.98 0 13.788a.75.75 0 11-1.06-1.06 8.25 8.25 0 000-11.668.75.75 0 010-1.06z"
              />
              <path
                d="M15.932 7.757a.75.75 0 011.061 0 6 6 0 010 8.486.75.75 0 01-1.06-1.061 4.5 4.5 0 000-6.364.75.75 0 010-1.06z"
              />
            </svg>
          </button>
          <div
            class="absolute top-0 left-0 right-0 group-hover:opacity-100 opacity-0 transition-opacity duration-200 -translate-y-[200px] h-[200px] w-[30px] bg-black/50 rounded-lg p-2.5 -ml-1.5"
          >
            <input
              type="range" :value="currentVolume" step="0.01" min="0" max="1" class="h-2 w-[180px] transform -rotate-90 -translate-x-[85px] translate-y-[75px]"
              @change="setVolume"
            >
          </div>
        </div>
        <button class="pointer-events-auto" @click="toggleFullscreen">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
            <path
              fill-rule="evenodd"
              d="M15 3.75a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0V5.56l-3.97 3.97a.75.75 0 11-1.06-1.06l3.97-3.97h-2.69a.75.75 0 01-.75-.75zm-12 0A.75.75 0 013.75 3h4.5a.75.75 0 010 1.5H5.56l3.97 3.97a.75.75 0 01-1.06 1.06L4.5 5.56v2.69a.75.75 0 01-1.5 0v-4.5zm11.47 11.78a.75.75 0 111.06-1.06l3.97 3.97v-2.69a.75.75 0 011.5 0v4.5a.75.75 0 01-.75.75h-4.5a.75.75 0 010-1.5h2.69l-3.97-3.97zm-4.94-1.06a.75.75 0 010 1.06L5.56 19.5h2.69a.75.75 0 010 1.5h-4.5a.75.75 0 01-.75-.75v-4.5a.75.75 0 011.5 0v2.69l3.97-3.97a.75.75 0 011.06 0z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
